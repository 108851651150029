
.course-box {
  padding: 30px;
  .top-bar {
    display: flex;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      width: 288px;
      height: 179px;
      background: url("../../../assets/image/teacherDataCenter/course-bg.png") no-repeat;
      background-size: cover;
    }
    .right {
      padding: 16px 0;
      margin-left: 20px;
      width: 1%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 20px;
      }
      .second {
        color: #666;
      }
    }
  }
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.course-table {
  margin-top: 10px;
  color: #232324;
  .tr {
    display: flex;
    text-align: center;
    background: #F0F3FF;
    border-bottom: 2px solid #fff;
    &:last-child {
      border-bottom: none;
    }
    .td {
      border-right: 2px solid #fff;
      padding: 15px;
      &.first {
        width: 50px;
      }
      &:last-child {
        border-right: none;
      }
    }
    &.th {
      background: #D3DDFF;
    }
  }
}
